import get from 'lodash/get'
import Layout from '../../components/layout'
import React from 'react'
import Helmet from 'react-helmet'
import { siteMetadata } from '../../../gatsby-config'
import legende from './legende-du-Colibri.png'
import stars from './stars.jpg'

class Pourquoi extends React.Component {
  render() {
    const title = 'Le pourquoi du comment'
    return (
      <Layout location={this.props.location}>
        <Helmet
          title={`${title} | ${get(siteMetadata, 'title')}`}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            {
              name: 'twitter:site',
              content: `@${get(siteMetadata, 'twitter')}`,
            },
            { property: 'og:title', content: title },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:description',
              content: get(siteMetadata, 'description'),
            },
            {
              property: 'og:url',
              content: `${get(siteMetadata, 'siteUrl')}/le-pourquoi-du-comment`,
            },
            {
              property: 'og:image',
              content: `${get(siteMetadata, 'siteUrl')}/img/logo.png`,
            },
          ]}
        />
        <section className="text-left">
          <div className="container">
          <div className="row">
            <div className="col-md-8 main-text">
              <h1>Le pourquoi du comment</h1>
              <p className="lead text-muted">Vous connaissez sans doute <a href="https://www.colibris-lemouvement.org/mouvement/nos-valeurs">la légende du Colibri</a>, qui s'affaire à éteindre l'incendie, faisant sa part, si infime fût elle, et provoquant par un changement personnel, une réaction dans son entourage.<br />
              <em>> Voir infographie ci-contre.</em></p>
              <p className="lead text-muted">Ce que la légende ne précise pas trop, c'est que malgré toutes les bonnes volontés, éteindre l'incendie n'est possible que par une bonne organisation des différents animaux, afin de coordonner leurs actions, et d'avoir une chance d'être plus efficaces dans la lutte contre l'incendie.</p>
              <p className="lead text-muted">Dans des mouvements citoyens et des projets collectifs, cela se traduit en particulier par l'utilisation d'outils de communication, d'outils d'apprentissage en ligne, de rencontres physiques régulières, etc.<br />
              Avec Internet et les outils collaboratifs en ligne, de nombreuses solutions existent pour communiquer et collaborer, a priori gratuitement.</p>
              <p className="lead text-muted"> Depuis quelques années, les révélations d'Edward Snowden concernant la surveillance de masse et l'émergence des géants du Web (notamment les GAFAM :Google, Apple, Facebook, Amazon et Microsoft), nous invitent à plus de vigilance sur notre utilisation d'Internet.  L'utilisation de ces services devient incohérente et contraire à des valeurs écologiques et humanistes.</p>
            </div>
            <div className="col-md-4 text-center">
              <h2>La légende du Colibri : comment éteindre l'incendie ?</h2>
              <img src={legende} alt="légende du colibris" />
            </div>
          </div>
          </div>
        </section>

        <section id="bad-internet" className="bg-gray text-white text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="section-heading">Le côté obscur de l'Internet centralisé</h2>
                <hr className="border-white" />
                <br /><br />
                <blockquote className="blockquote">"L'Internet? Je l'appelerais plutôt <strong>l'Inter-pas-très-net</strong>!"
                <footer className="blockquote-footer">Pierre Rabhi</footer></blockquote>
              </div>
            </div>
          </div>
          <br />
          <div className="container gafam-box">
            <div className="row justify-content-md-center">
              <div className="col-lg-4 col-md-6">
                  <i
                    className="fa fa-envira"
                    data-emergence="hidden"
                  />
                  <h3>Impact écologique</h3>
                  La redondance des serveurs, le matériel en perpétuelle évolution, les données dupliquées et archivées sans limite, le recyclage des composants difficile,...  font que les gros silos de données ne sont pas très écologiques.
              </div>
              <div className="col-lg-4 col-md-6">
                  <i
                    className="fa fa-user-secret"
                    data-emergence="hidden"
                  />
                  <h3>La vie privée menacée</h3>
                  Les conditions générales d'utilisation vous exproprient de vos données personnelles, qui peuvent être revendues à des sociétés commerciales, ou transmises aux états qui en font la demande.
              </div>
              <div className="col-lg-4 col-md-6">
                  <i
                    className="fa fa-newspaper-o"
                    data-emergence="hidden"
                  />
                  <h3>Fausses informations</h3>
                  Les algorithmes affichant des informations faussent l'objectivité d'un débat, ne proposant que des opinions similaires. Aussi il est possible de mettre en avant des informations erronées simplement en payant pour avoir de la visibilité sur les réseaux sociaux.
              </div>
              
              <div className="col-lg-4 col-md-6">
                  <i
                    className="fa fa-money"
                    data-emergence="hidden"
                  />
                  <h3>Plein d'argent, peu d'impôts</h3>
                  3000 milliards de capitalisation boursière en cumulé pour les GAFAM, 546 milliards de trésorerie, mais très peu de retombées locales à cause de nombreuses optimisations fiscales.
              </div>
              <div className="col-lg-4 col-md-6 ">
                  <i
                    className="fa fa-flag"
                    data-emergence="hidden"
                  />
                  <h3>Perte de notre souveraineté</h3>
                  Nous acceptons que toutes nos données soient sous la loi américaine, et nous participons ainsi à une situation de monopole de certaines entreprises, notamment sur les questions de recherche de développement.
              </div>
              <div className="col-lg-4 col-md-6">
                  <i className="fa fa-balance-scale" data-emergence="hidden" />
                  <h3>Vers moins d'égalité</h3>
                  Les progrès dans le secteur de la santé (en médecine, en génétique), ou dans l'éducation, réalisés grâce aux recherches très coûteuses de ces grosses entreprises, ne pourront qu'avoir des personnes très riches comme bénéficiaires, accentuant les inégalités.
              </div>
            </div>
          </div>
        </section>

        {/* <section id="solution" className="text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="section-heading">Une solution existe : re-décentraliser Internet</h2>
                <hr className="border-primary" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-lg-8">
                <p className="lead text-muted">
                  Internet avait été conçu comme un réseau décentralisé,<br />
                  il est temps de revenir à sa structure initiale.<br /><br />
                  Sortir nos informations des gros silos de données pour revenir à des petites structures ouvertes et inter-connectées, c'est possible avec un peu de savoir faire technique et de la bonne volonté, mais que faire si l'on n'est qu'un simple utilisateur avec qu'une connaissance partielle du fonctionnement de tous ces outils ?<br /><br /> 
                  <strong><em>Avec un peu d'appuis, c'est plus facile qu'il n'y parait!</em></strong>
                </p>
              </div>
            </div>
          </div>
        </section> */}

        <section
          className="bg-primary text-white text-center color-inverse"
          id="free-software"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="section-heading">Une solution existe : les logiciels libres et les hébergeurs alternatifs</h2>
                <hr className="border-white" />
              </div>
            </div>
          </div>
          <div className="container">
          Internet avait été conçu comme un réseau décentralisé, qui donne du pouvoir et de la liberté aux citoyens.<br /><br />
Sortir nos informations des gros silos de données pour revenir à des petites structures ouvertes et inter-connectées, c'est possible avec un peu de savoir faire technique et de la bonne volonté.<br /><br />
Dans le cadre du mouvement plus global des logiciels libres, et par sa rencontre et collaboration avec <a href="https://framasoft.org" title="Voir le site de l'association Framasoft">l'association Framasoft</a>, Colibris a rejoint le <a href="https://chatons.org" title="Voir le site des chatons">Collectif d'Hébergeurs Alternatifs Transparents Ouverts, Neutres et Solidaires (CHATONS)</a> et met à disposition gratuitement des solutions libres et respectueuses de la vie privée. 
          </div>
        </section>

        <section id="educ-pop" style={{
          backgroundImage: 'url('+stars+')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',         
        }}>
          <div className="container text-center">
          <h1 style={{color: '#fff'}}>Au delà des outils,<br />une démarche plus large d'éducation populaire</h1>
            <div className="row align-items-center">
              <div className="col-md-4 text-center slide-up" data-emergence="hidden">
                <div className="">
                  <h2 className="section-heading">Se former</h2>
                  L'Université des Colibris vous propose des parcours sur les outils libres avec un apport méthodologique de gestion de projets collaboratifs.
                </div>
              </div>
              <div className="col-md-4 text-center slide-up" data-emergence="hidden">
                <div className="">
                  <h2 className="section-heading">Débattre</h2>
                  L'Agora des Colibris anime une consultation citoyenne sur le numérique, avec des rencontres, des conférences débats, menant à la co-rédaction d'un livre sur le sujet.
                </div>
              </div>
              <div className="col-md-4 text-center slide-up" data-emergence="hidden">
                <div className="">
                  <h2 className="section-heading">Co-construire</h2>
                  Sur vos propositions, des tutoriels, vidéos et articles sont créés pour enrichir ce site.<br />
Pour les plus technophiles, des codings party sont organisées pour améliorer les services existants.
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Pourquoi
